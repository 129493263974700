@use "styles/variables/colors/colors";
@import "../AppDev/styles.module";

.appdev {
  &__info-value {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.75rem;
    line-height: 2rem;
    margin-right: 10px;
    color: colors.$iconSuccess;
  }

  &__info-text {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1rem;
    text-transform: uppercase;
    color: colors.$defaultTextColor;
  }
  &__content-footer {
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &__content-footer *{
    display: flex;
    align-items: center;
  }
}